function SET_SCROLL_CLASS() {
  const BODY_ELEMENT = document.querySelector('body');
  CHECK_SCROLL() ? BODY_ELEMENT.setAttribute('data-scrolled', '') : BODY_ELEMENT.removeAttribute('data-scrolled');
}

function CHECK_SCROLL() {
  return document.documentElement.scrollTop > 0;
}

export function INIT_SCROLL_CHECK() {
  SET_SCROLL_CLASS();
  window.addEventListener('scroll', SET_SCROLL_CLASS)
}
