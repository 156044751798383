function TRIGGER_HEADER(header) {
  if (header.getAttribute('data-active') === 'false') header.setAttribute('data-active', 'true');
  else header.setAttribute('data-active', 'false');
}

export function INIT_MOBILE_TOGGLE() {
  const MOBILE_TOGGLE = document.querySelector('[data-navbar-toggle]'),
    MOBILE_NAV = document.querySelector('[data-mobile-nav]'),
    MOBILE_NAV_LINKS = document.querySelectorAll('[data-mobile-nav-link]'),
    HEADER = document.querySelector('[data-header]');
  if (MOBILE_TOGGLE && HEADER) {

    MOBILE_TOGGLE.addEventListener('click', () => TRIGGER_HEADER(HEADER));

    window.addEventListener('resize', () => {
      if (window.matchMedia("(min-width: 1200px)").matches) {
        if (HEADER.getAttribute('data-active') === 'active') HEADER.setAttribute('data-active', 'false');
      }
    })

    document.addEventListener('click', e => {
      if (!e.composedPath().includes(MOBILE_NAV) && !e.composedPath().includes(MOBILE_TOGGLE) && HEADER.getAttribute('data-active') === 'true')
        HEADER.setAttribute('data-active', 'false');
    });

    MOBILE_NAV_LINKS.forEach(MOBILE_NAV_LINK => {
      const NAV_LINK = new URL(MOBILE_NAV_LINK.href),
        NAV_LINK_HASH = NAV_LINK.hash;

      MOBILE_NAV_LINK.addEventListener('click', () => {
        if (!!NAV_LINK_HASH) HEADER.setAttribute('data-active', 'false');
      })
    })

  }
}
