import * as Klaro from 'klaro';
import {KLARO_CONFIG} from './config.ccm';
import {INIT_EVENTS} from './events.ccm';

import {INIT_NEW_INLINE_CCM_CONTENT} from "./inline-consent";

const CCM_TRIGGER = document.querySelector("[data-cookie-button]");

function ADD_CARET_EVENTS() {
    setTimeout(() => {
        const CARETS = document.querySelectorAll('.cm-caret a');
        CARETS.forEach(CARET => {
            CARET.addEventListener('click', () => {
                if (CARET.hasAttribute('data-active')) CARET.removeAttribute('data-active');
                else CARET.setAttribute('data-active', '');
            });
        });
    }, 50);
}

function INIT_KLARO() {
    window.klaro = Klaro;
    window.klaroConfig = KLARO_CONFIG;

    Klaro.setup(KLARO_CONFIG);
    INIT_NEW_INLINE_CCM_CONTENT();

    const KLARO_MODAL = document.querySelector('#klaro');
    const LEARN_MORE_BUTTON = document.querySelector('.cm-link.cn-learn-more');
    if (KLARO_MODAL && LEARN_MORE_BUTTON) LEARN_MORE_BUTTON.addEventListener('click', () => ADD_CARET_EVENTS());
}

export function INIT_COOKIE_MANAGER() {
    INIT_KLARO();
    INIT_EVENTS();

    CCM_TRIGGER.addEventListener("click", () => {
        Klaro.show(KLARO_CONFIG, true);
        const KLARO_MODAL = document.querySelector('#klaro');
        if (KLARO_MODAL) ADD_CARET_EVENTS();
    })
}

