import "@popperjs/core/dist/esm/index";

import {INIT_COOKIE_MANAGER} from "./js/ccm/init.ccm";
import {INIT_MOBILE_TOGGLE} from "./js/init.mobile-navigation";
import {INIT_ANIMATE_ON_SCROLL} from "./js/init.animate-on-scroll";
import {INIT_SOCIAL_NAV} from "./js/init.social-nav";
import {INIT_SCROLL_CHECK} from "./js/init.scroll-check";
import {INIT_TRANSITIONS} from 'js/init.transitions';
import {INIT_DARKMODE_SWITCH} from 'js/init.darkmode-switch';
import {INIT_TOOLTIPS} from "./js/init.tooltips";
import {INIT_PROJECT_CARD_HOVER} from "./js/init.project-card-hover";
import {INIT_FORM} from "./js/init.form";

INIT_COOKIE_MANAGER();
INIT_ANIMATE_ON_SCROLL();
INIT_MOBILE_TOGGLE();
INIT_SOCIAL_NAV();
INIT_SCROLL_CHECK();
INIT_TRANSITIONS();
INIT_DARKMODE_SWITCH();
INIT_TOOLTIPS();
INIT_PROJECT_CARD_HOVER();
INIT_FORM();
