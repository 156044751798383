function RETURN_EVENT_POSITION(item) {
  return item.dataset.trackingPosition && item.dataset.trackingPosition !== ""
    ? item.dataset.trackingPosition
    : "undefined";
}

function RETURN_EVENT_LABEL(item) {
  return item.dataset.trackingLabel && item.dataset.trackingLabel !== ""
    ? item.dataset.trackingLabel
    : "undefined";
}

export function INIT_EVENTS() {
  const TRACKING_EVENTS = document.querySelectorAll("[data-tracking-event]");

  TRACKING_EVENTS.forEach(TRACKING_EVENT => TRACKING_EVENT.addEventListener("click", () => {
    if (typeof (gtag) === 'function') {
      gtag("event", TRACKING_EVENT.dataset.trackingEvent, {
        button_position: RETURN_EVENT_POSITION(TRACKING_EVENT),
        button_label: RETURN_EVENT_LABEL(TRACKING_EVENT)
      });
    }

    if (typeof (fbq) === 'function') {
      fbq('track', TRACKING_EVENT.dataset.trackingEvent, {
        button_position: RETURN_EVENT_POSITION(TRACKING_EVENT),
        button_label: RETURN_EVENT_LABEL(TRACKING_EVENT)
      });
    }
  }));
}
