import Aos from "aos";

export function INIT_ANIMATE_ON_SCROLL() {
  Aos.init({
    //offset: 0,
    duration: 1200,
    easing: 'cubic-bezier(.15,.8,.4,1)',
    startEvent: 'DOMContentLoaded',
    delay: 0,
    once: true

    // disable: false,
    // startEvent: 'DOMContentLoaded',
    // initClassName: 'aos-init',
    // animatedClassName: 'aos-animate',
    // useClassNames: true,
    // disableMutationObserver: false,
    // debounceDelay: 50,
    // throttleDelay: 99,
    // mirror: false,
    // anchorPlacement: 'top-bottom',
  });

  window.addEventListener('DOMContentLoaded', () => {
    Aos.refresh();
  })
}
