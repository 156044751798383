function BUILD_OBESERVER(rootElement) {
  const ATTRIBUTE_ROOT = document.querySelector('body'),
    OBSERVER_OPTIONS = {
    ROOT_ELEMENT: rootElement,
    rootMargin: '-50% 0px -50% 0px'
  }

  return new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) ATTRIBUTE_ROOT.setAttribute('data-bs-theme', 'dark')
      else ATTRIBUTE_ROOT.setAttribute('data-bs-theme', 'light')
    });
  }, OBSERVER_OPTIONS);
}

export function INIT_DARKMODE_SWITCH() {
  document.querySelectorAll('[data-darkmode]').forEach(SECTION => BUILD_OBESERVER(SECTION).observe(SECTION))
}
