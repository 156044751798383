import "./inline-consent";

var KLARO_DOMAIN = window.location.hostname;
export const KLARO_CONFIG = {
  cookieName: 'ccm.midiafilm.de',
  noAutoLoad: false,
  htmlTexts: true,
  embedded: false,
  groupByPurpose: true,
  cookieExpiresAfterDays: 120,
  default: false,
  mustConsent: false,
  acceptAll: true,
  hideDeclineAll: false,
  hideLearnMore: false,
  noticeAsModal: false,
  disablePoweredBy: true,

  translations: {
    de: {
      privacyPolicyUrl: "/datenschutz",
      close: "Schließen",
      decline: "Nur Notwendige",
      ok: "Alle akzeptieren",
      save: "Speichern",

      consentModal: {
        title: "Einstellungen zum Datenschutz",
        description: "Auf dieser Website werden Daten wie z.B. Cookies gespeichert, um wichtige Funktionen der Website, einschließlich Analysen, Marketingfunktionen und Personalisierung zu ermöglichen. Die Einstellungen können jederzeit angepasst oder alle Cookies akzeptiert werden.",
        inlineDescription: "Ich bin damit einverstanden, dass mir dieser externe Inhalt angezeigt wird. Personenbezogene Daten können an Drittplattformen übermittelt werden. Mehr dazu in unserer {privacyPolicy}."
      },

      privacyPolicy: {
        name: 'Datenschutzerklärung',
        text: 'Weitere Details in unserer {privacyPolicy}.'
      },

      consentNotice: {
        changeDescription: "Es gab Änderungen seit Ihrem letzten Besuch, bitte aktualisieren Sie Ihre Auswahl.",
        description: "<strong>Auf dieser Website werden Daten wie z.B. Cookies gespeichert, um wichtige Funktionen der Website, einschließlich Analysen, Marketingfunktionen und Personalisierung zu ermöglichen.</strong> Die Einstellungen können jederzeit angepasst oder alle Cookies akzeptiert werden.",
        learnMore: "Anpassen"
      },

      acceptSelected: 'Auswahl speichern',
      acceptAll: 'Alle akzeptieren',

      service: {
        disableAll: {
          title: "Alle Dienste aktivieren oder deaktivieren",
          description: 'Mit diesem Schalter alle Anwendungen aktivieren/deaktivieren.'
        },
        required: {
          title: " (erforderlich)",
          description: "required description"
        },
        purpose: "Zweck",
        purposes: "Zwecke"
      },

      contextualConsent: {
        headline: "Empfohlener externer Inhalt",
        acceptAlways: "Immer laden",
        acceptOnce: "Akzeptieren und Inhalte laden",
        description: "Möchten Sie von {title} bereitgestellte externe Inhalte laden?"
      },

      purposeItem: {
        service: "Dienst",
        services: "Dienste"
      },

      purposes: {
        required: {
          title: 'Technisch notwendig',
          description: 'Diese Cookies sind für eine gute Funktionalität unserer Website erforderlich und können in unserem System nicht ausgeschaltet werden.'
        },
        marketing: {
          title: 'Marketing',
          description: 'Durch Marketing Cookies können wir die individuelle Nutzererfahrung unserer Website stetig verbessern'
        },
        functional: {
          title: 'Funktional',
          description: 'Wir verwenden diese Cookies, um die Funktionalität der Webseite zu verbessern, relevante Inhalte darzustellen und die Personalisierung zu ermöglichen.'
        },
        analytics: {
          title: 'Analyse',
          description: 'Wir verwenden diese Cookies, um statistische Informationen über unsere Website und Besucher anonymisiert bereitzustellen. Sie werden zur Leistungsmessung und -verbesserung verwendet.'
        }
      },

      cookieManager: {
        description: 'Für die Einstellung dieses Cookie-Managers wird ein Cookie gesetzt. Dieser dient ausschließlich der Speicherung der Einstellungen wie sie getroffen wurden und ist demnach technisch erforderlich.'
      },
      googleAnalytics: {
        description: 'Um die Seite fortlaufend zu verbessern, analysieren wir das Verhalten der Besucher mit Google Analytics'
      },
      facebookPixel: {
        description: 'Um die Seite fortlaufend zu verbessern, analysieren wir das Verhalten der Besucher mit Facebook Pixel'
      },
      vimeo: {
        description: 'Zur Anzeige von Videos verwenden wir Vimeo.'
      },
      youtube: {
        description: 'Zur Anzeige von Videos verwenden wir Youtube.'
      }
    }
  },

  services: [
    {
      name: 'cookieManager',
      title: 'Cookie Manager',
      purposes: ['required'],
      required: true
    },
    {
        name: 'googleAnalytics',
        title: 'Google Analytics',
        cookies: [
            ['_ga', '/', '.' + KLARO_DOMAIN],
            ['_gid', '/', '.' + KLARO_DOMAIN],
            [/^_ga_.*$/i, '/', '.' + KLARO_DOMAIN],
            [/^_gat_.*$/i, '/', '.' + KLARO_DOMAIN],
            [/^_gac_.*$/i, '/', '.' + KLARO_DOMAIN],
            [/^_gac_.*$/i, '/', '.' + KLARO_DOMAIN]
        ],
        purposes: ['analytics']
    },
    {
      name: 'facebookPixel',
      title: 'Facebook Pixel',
      cookies: [
        ['_fbp', '/', '.' + KLARO_DOMAIN]
      ],
      purposes: ['analytics', 'marketing']
    },
    {
      name: 'vimeo',
      purposes: ['functional']
    },
    {
      name: 'youtube',
      purposes: ['functional']
    }
  ]
};

