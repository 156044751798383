import {SUBMIT_FORM} from "./form/submit/submit";

const CONTACT_FORMS = document.querySelectorAll('form');

export function INIT_FORM() {


  CONTACT_FORMS.forEach(FORM => {
    let FIELDS = {};

    const FORM_FIELD_NODES = FORM.querySelectorAll('[name][id]');
    const FORM_SUCCESS_BOX = FORM.querySelector('[data-form-success]');
    const SUBMIT_BUTTON = FORM.querySelector('button[type="submit"]');

    FORM_FIELD_NODES.forEach(field => {
      FIELDS[field.name] = field
    });

    const HANDLE_ERROR = response => {
      let errors = [];

      for (const key in FIELDS) {

        if (response.hasOwnProperty(key)) {

          FIELDS[key].setAttribute('data-validation-status', 'invalid');
          const ERROR_TEXT = FIELDS[key].parentNode.querySelector('.form-alert');
          if (ERROR_TEXT) {
            ERROR_TEXT.innerHTML = response[key];
          }

          // Array.prototype.push.apply(errors, [response[key]]);
          // console.log(response);


        } else {
          FIELDS[key].setAttribute('data-validation-status', 'valid');
        }
      }
    }


    const REQUEST_HANDLER = e => {
      if (e.target.status === 200) {

        for (const key in FIELDS) {
          FIELDS[key].setAttribute('data-validation-status', 'valid');
        }

        SUBMIT_BUTTON.setAttribute('data-sending', '')

        setTimeout(() => {
          SUBMIT_BUTTON.removeAttribute('data-sending');
          FORM_SUCCESS_BOX.setAttribute('data-form-success', 'true')
        }, 1500);

      } else {
        HANDLE_ERROR(JSON.parse(e.target.response));
      }
    };

    function FORM_SUBMIT(submitEvent) {
      submitEvent.preventDefault();

      const REQUEST = new XMLHttpRequest();
      REQUEST.open('POST', submitEvent.target.action);
      REQUEST.onload = REQUEST_HANDLER;
      REQUEST.send(new FormData(submitEvent.target));
    }

    FORM.addEventListener('submit', e => {
      FORM_SUBMIT(e);
    });
  });
}
