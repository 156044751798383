function CAPITALIZE(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

function CREATE_CCM_TEXT_ELEMENT(klaroConfig) {
    const KLARO_TEXT = klaroConfig.translations.de,
        KLARO_PRIVACY_LINK = `<a title="Zur Seite ${KLARO_TEXT.privacyPolicy.name}" href="${KLARO_TEXT.privacyPolicyUrl}">${KLARO_TEXT.privacyPolicy.name}</a>`,
        INLINE_DESCRIPTION = `${KLARO_TEXT.consentModal.inlineDescription}`;

    return `<p class="cm-additional-text">${INLINE_DESCRIPTION.replace('{privacyPolicy}', KLARO_PRIVACY_LINK)}</p>`
}

function CREATE_CCM_HEADLINE(klaroConfig) {
    return `<p class="context-headline">${klaroConfig.translations.de.contextualConsent.headline}</p>`
}

function REMOVE_OLD_BUTTONS(inlineContent) {
    setTimeout(() => {
        if (inlineContent.querySelector('.cm-buttons')) inlineContent.querySelector('.cm-buttons').remove();
    }, 10)
}

function CREATE_NEW_BUTTON(ccmManager, placeholderElement) {
    const CCM_BUTTON_HOLDER = document.createElement('div'),
        SERVICE = placeholderElement.getAttribute('data-name');

    CCM_BUTTON_HOLDER.classList.add('cm-consent-button--holder');
    CCM_BUTTON_HOLDER.innerHTML = `<button class="cm-consent-button">
            <div class="cm-consent-button-slider"><span class="sr-only">externen Inhalt zulassen</span></div>
        </button><p class="cm-consent-label">Externen Inhalt von <span class="service">${CAPITALIZE(SERVICE)}</span> immer erlauben</p>`;

    const CCM_BUTTON = CCM_BUTTON_HOLDER.querySelector('.cm-consent-button');

    CCM_BUTTON.addEventListener('click', () => {
        CCM_BUTTON.setAttribute('data-active', '');
        setTimeout(() => {
            ccmManager.updateConsent(SERVICE, true);
            ccmManager.saveConsents('contextual-accept');
            ccmManager.applyConsents(false, true, SERVICE);
        }, 350)
      setTimeout(() => {
        CCM_BUTTON.removeAttribute('data-active');
      }, 500)
    })

    return CCM_BUTTON_HOLDER;
}

function PASTE_CCM_INLINE_CONTENT(ccmManager, placeholderElement, service, inlineContent, klaroConfig) {
    REMOVE_OLD_BUTTONS(inlineContent);
    inlineContent.insertAdjacentHTML('afterbegin', CREATE_CCM_HEADLINE(klaroConfig));
    inlineContent.insertAdjacentHTML('beforeend', CREATE_CCM_TEXT_ELEMENT(klaroConfig));
    inlineContent.querySelector('.cm-additional-text').before(CREATE_NEW_BUTTON(ccmManager, placeholderElement));
}

export function INIT_NEW_INLINE_CCM_CONTENT() {
    const KLARO = window.klaro,
        KLARO_CONFIG = window.klaroConfig,
        CCM_MANAGER = KLARO.getManager(KLARO_CONFIG),
        PLACEHOLDERS = document.querySelectorAll('[data-type="placeholder"][data-name]');

    PLACEHOLDERS.forEach(PLACEHOLDER => {
        const INLINE_CONTENT = PLACEHOLDER.querySelector('.context-notice'),
            SERVICE = PLACEHOLDER.getAttribute('data-name');

        PASTE_CCM_INLINE_CONTENT(CCM_MANAGER, PLACEHOLDER, SERVICE, INLINE_CONTENT, KLARO_CONFIG)
        CCM_MANAGER.watch({
            update: (obj, name) => {
                if (name === 'applyConsents') REMOVE_OLD_BUTTONS(PLACEHOLDER.querySelector('.context-notice'));
            }
        });
    });
}
