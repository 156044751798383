export function INIT_SOCIAL_NAV() {
  const TOGGLE = document.querySelector('[data-social-menu-toggle]'),
    SOCIAL_MENU = document.querySelector('[data-social-menu]'),
    SOCIAL_MENU_BIG_HOLDER = document.querySelector('[data-social-menu-big-holder]');

  if (TOGGLE && SOCIAL_MENU_BIG_HOLDER && SOCIAL_MENU) {
    TOGGLE.addEventListener('click', () => {

      if (TOGGLE.hasAttribute('data-active')) TOGGLE.removeAttribute('data-active');
      else TOGGLE.setAttribute('data-active', '');

      if (SOCIAL_MENU_BIG_HOLDER.hasAttribute('data-active')) {
        SOCIAL_MENU.removeAttribute('data-inactive');
        SOCIAL_MENU_BIG_HOLDER.removeAttribute('data-active');
      } else {
        SOCIAL_MENU.setAttribute('data-inactive', '');
        SOCIAL_MENU_BIG_HOLDER.setAttribute('data-active', '');
      }
    });

    document.addEventListener('click', e => {
      if (!e.composedPath().includes(SOCIAL_MENU_BIG_HOLDER) && !e.composedPath().includes(TOGGLE) && SOCIAL_MENU_BIG_HOLDER.hasAttribute('data-active')) {
        SOCIAL_MENU.removeAttribute('data-inactive');
        SOCIAL_MENU_BIG_HOLDER.removeAttribute('data-active');
        TOGGLE.removeAttribute('data-active');
      }
    });
  }
}
