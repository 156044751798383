export function INIT_PROJECT_CARD_HOVER() {
  const PROJECT_CARDS = document.querySelectorAll('[data-project-card][data-video]');

  PROJECT_CARDS.forEach(projectCard => {
    const PROJECT_VIDEO = projectCard.querySelector('video:not([autoplay])');
    let hasEventListeners = false;

    function handleMouseEnter() {
      PROJECT_VIDEO.play()
    }

    function handleMouseLeave() {
      PROJECT_VIDEO.pause()
    }

    function HANDLE_LISTENERS() {
      if (window.matchMedia("(min-width: 991.99999px)").matches && !hasEventListeners) {
        projectCard.addEventListener('mouseenter', handleMouseEnter);
        projectCard.addEventListener('mouseleave', handleMouseLeave);
        hasEventListeners = true;
      } else if (!window.matchMedia("(min-width: 991.99999px)").matches && hasEventListeners) {
        projectCard.removeEventListener('mouseenter', handleMouseEnter);
        projectCard.removeEventListener('mouseleave', handleMouseLeave);
        hasEventListeners = false;
      }
    }

    if (PROJECT_VIDEO) {
      HANDLE_LISTENERS();
      window.addEventListener('resize', () => HANDLE_LISTENERS());
    }
  })
}
